import React from "react";

const Soal = React.lazy(() => import("../Modules/Soal/Soal"));

const routes = [
  {
    path: "/",
    component: Soal,
    exact: true,
    name: "Soal",
  },
];

export default routes;
