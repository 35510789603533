import React, { Suspense } from "react";
import { Route, Switch } from "react-router";
import "./App.css";
import routes from "./Routes";

function App() {
  console.log(
    "%c HRIS TIU version ",
    "background: #203354; color: #fff",
    process.env.REACT_APP_HRIS_PSIKOTES_VERSION
  );
  const simpleRoute = routes.map((route) => ({
    name: route.name,
    path: route.path,
  }));

  return (
    <Suspense fallback={"loading..."}>
      <Switch>
        {routes.map((route, i) => (
          <Route
            path={route.path}
            exact={route.exact}
            routes={simpleRoute}
            component={route.component}
            key={i}
          />
        ))}
      </Switch>
    </Suspense>
  );
}

export default App;
